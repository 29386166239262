import React from 'react'
import styled from 'styled-components'
import BannerSection from '../components/banner-section'
import SEO from '../components/seo'
import SignUpForm from '../components/sign-up-form'

export const Container = styled.section`
  background-color: ${({ theme }) => theme.palette.gray10};
`

export const SignUpBanner = styled(BannerSection)`
  height: 382px;
`

const SignUp: React.FC = () => {
  return (
    <>
      <SEO title="Sign Up" />
      <Container>
        <SignUpBanner>
          <section>
            <h1>Stay connected</h1>
            <p>Sign up to receive updates on Sitoo’s API specifications</p>
          </section>
        </SignUpBanner>
        <SignUpForm />
      </Container>
    </>
  )
}

export default SignUp
