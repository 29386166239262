import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { Container } from './styled'
import { ChevronDownQuery } from './__generated__/ChevronDownQuery'

const FORM_ID = 'sitoo-sign-up'

const SignUpForm: React.FC = () => {
  const [ready, setReady] = useState(false)

  const loadScript = () => {
    const script = document.createElement(`script`)
    script.src = `//js.hsforms.net/forms/v2.js`
    script.onload = () => setReady(true)
    document.head.appendChild(script)
  }

  useEffect(() => {
    loadScript()
  }, [])

  useEffect(() => {
    // TODO: we should add a fallback in case if the Hubspot is not available
    if (ready && window.hbspt) {
      window.hbspt.forms.create({
        target: `#${FORM_ID}`,
        portalId: '5441101',
        formId: '2a4dda24-7ed8-4caf-8792-d2918da3df6d',
      })
    }
  }, [ready])

  const { allFile } = useStaticQuery<ChevronDownQuery>(
    graphql`
      query ChevronDownQuery {
        allFile(filter: { relativePath: { eq: "chevron-small-down.svg" } }) {
          nodes {
            publicURL
          }
        }
      }
    `,
  )

  const arrowBackground = allFile.nodes[0]?.publicURL

  if (!arrowBackground) return null

  return <Container id={FORM_ID} arrowBackground={arrowBackground} />
}

export default SignUpForm
