import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Section } from './styled'
import { BannerImageQuery } from './__generated__/BannerImageQuery'

const BannerSection: React.FC = (props) => {
  const { allFile } = useStaticQuery<BannerImageQuery>(
    graphql`
      query BannerImageQuery {
        allFile(
          filter: { relativePath: { eq: "banner-section-background.jpg" } }
        ) {
          nodes {
            publicURL
          }
        }
      }
    `,
  )

  const backgroundUrl = allFile.nodes[0]?.publicURL

  if (!backgroundUrl) return null

  return <Section backgroundUrl={backgroundUrl}>{props.children}</Section>
}

export default BannerSection
