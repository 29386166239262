import styled from 'styled-components'
import { DisplaySmall } from '../../styles/shared'

type Props = {
  backgroundUrl: string
}

export const Section = styled.section<Props>`
  height: 592px;
  width: 100%;
  margin-top: 67px;
  background-image: url(${({ backgroundUrl }) => backgroundUrl});
  background-color: ${({ theme }) => theme.palette.blue80};
  background-size: cover;
  background-position: center center;
  color: ${({ theme }) => theme.palette.white};
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0px 20px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 382px;
  }

  p {
    ${DisplaySmall}
    max-width: 560px;
    margin: auto;
  }

  & > section {
    margin-bottom: 40px;
  }
`
