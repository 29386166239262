import styled from 'styled-components'
import { Body02, Caption } from '../../styles/shared'
import { ButtonBase, FontVariant, GreenVariant } from '../button/styled'

type Props = {
  arrowBackground: string
}

export const Container = styled.div<Props>`
  max-width: 490px;
  min-height: 490px;
  margin: -53px auto 100px;
  padding: 30px 24px;
  background-color: ${({ theme }) => theme.palette.white};
  display: grid;
  align-items: center;

  .hs-richtext {
    ${Caption}
    text-align: center;
  }

  .hs-button[type='submit'] {
    ${ButtonBase}
    ${GreenVariant}
    ${FontVariant}
    ${Body02}
    width: 100%;
    padding: 8px 0;
    margin: 0;
    appearance: none;
  }

  .input {
    margin-right: 0 !important;
  }

  .hs-form-field label {
    ${Body02}
    margin-bottom: 8px;
    display: block;
  }

  .hs-form-field .hs-error-msg {
    ${Caption}
  }

  .hs-input[type='text'],
  .hs-input[type='email'],
  textarea.hs-input,
  select.hs-input {
    height: 40px;
    width: 100% !important;
    padding: 0 16px;
    border: 1px solid;
    border-color: ${({ theme }) => theme.palette.gray40};
    appearance: none;

    &.error {
      color: ${({ theme }) => theme.palette.redBase};
      border-color: ${({ theme }) => theme.palette.redBase};
    }
  }

  textarea.hs-input {
    min-height: 100px;
    padding: 8px 16px;
  }

  select.hs-input {
    appearance: none;
    border-radius: 0;
    background-image: url(${({ arrowBackground }) => arrowBackground});
    background-position: right 16px center;
    background-repeat: no-repeat;
  }

  .hs-input[type='checkbox'] {
    margin: 0;
  }

  .field {
    margin-bottom: 16px;
  }

  .hs-error-msgs {
    list-style: none;
    padding: 8px 0 0;
    margin: 0;
  }

  .legal-consent-container {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    label {
      display: flex;
      align-items: center;
    }

    .hs-form-booleancheckbox-display > span {
      font-weight: normal;
      margin-left: 8px;
    }
  }

  .hs-error-msgs {
    color: ${({ theme }) => theme.palette.redBase};
  }

  .hs_error_rollup {
    display: none;
  }

  .submitted-message {
    text-align: center;
  }
`
